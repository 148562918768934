import React from 'react'
import Layout from "../components/layout";

const TOS = () =>{
    return (
    <Layout>
        <h1>Términos de Servicio</h1>
        <p>Este sistema le permite asegurar el espacio de ingreso a los sectores de uso turístico de las áreas silvestres protegidas del Área de Conservación Guanacaste. El ingreso está sujeto al pago del derecho de admisión según las tarifas establecidas. La reservación no constituye o suple el derecho de pago</p>
        <p>Para el caso del Sector Marino de ACG, es necesario hacer el depósito correspondiente para poder asegurar y su espacio, este pago debe de ser validado por el personal de ACG antes de confirmar y permitir el ingreso de la embarcación al ASP </p>
        
    </Layout>
    )
}

export default TOS
